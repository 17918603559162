import { createObjectURL } from 'blob-util';
import * as React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'wouter';
import FormattedRelative from '../components/FormattedRelative';
import Json from '../components/Json';
import Page from '../components/Page';
import Spinner from '../components/Spinner';
import UserToken from '../components/UserToken';
import { useUsersMap } from '../modules/users';
import { useApiCall, useInitApiCall } from './useApiCall';
import bytes from 'bytes';

const stateMap: Record<string, string> = {
  completed: 'text-success',
  failed: 'text-danger',
};

interface Props {}

const ReportingAdministrationContainer: React.FC<Props> = ({}) => {
  let [jobs, err] = useInitApiCall<any>('/admin/reporting');
  const apiCall = useApiCall();
  const users = useUsersMap();

  const handlePatchJob = React.useCallback(
    async (command: string, jobId: string) => {
      await apiCall<any>(`/admin/reporting`, {
        method: 'PATCH',
        body: JSON.stringify({ command, jobId }),
      });
      window.location.reload();
    },
    [],
  );

  const handleCancelJob = React.useCallback(
    (jobId: string) => {
      handlePatchJob('cancel', jobId);
    },
    [handlePatchJob],
  );

  const handleDownloadJob = React.useCallback(async (jobId: any) => {
    const url = new URL('/api/admin/reporting', window.location as any);
    const element = document.createElement('a');
    fetch(url, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ command: 'download', jobId: jobId }),
    }).then(async res => {
      if (res.status === 200) {
        const blob = await res.blob();

        const url = createObjectURL(blob);
        element.setAttribute('href', url);
        element.setAttribute('download', 'report.pdf');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    });
  }, []);

  let content = <Spinner />;

  if (jobs) {
    const rows = jobs.map((job: any) => (
      <tr>
        <td>
          <FormattedRelative value={job.timestamp} />
        </td>
        <td>
          {job.data.user_id ? (
            <UserToken small withText user={users![job.data.user_id]} />
          ) : (
            'unknown'
          )}
        </td>
        <td>
          <Link to={job.data.path}>{job.data.path}</Link>
        </td>
        <td className={stateMap[job.state] || 'text-warning'}>
          <span
            title={JSON.stringify(
              {
                stacktrace: job.stacktrace || null,
                failedReason: job.failedReason || null,
              },
              null,
              2,
            )}
          >
            {job.state}
          </span>
        </td>
        <td>{job.data?.size ? bytes(job.data.size) : '-'}</td>
        <td>
          {job.state === 'completed' && (
            <Button bsSize="xs" onClick={() => handleDownloadJob(job.id)}>
              <i className="fa fa-download"></i>
            </Button>
          )}
          {!['completed', 'failed'].includes(job.state) && (
            <Button bsSize="xs" onClick={() => handleCancelJob(job.id)}>
              <i className="fa fa-ban"></i>
            </Button>
          )}
        </td>
      </tr>
    ));
    content = (
      <>
        <table className="table table-striped">
          <thead>
            <th>Created at</th>
            <th>User</th>
            <th>url</th>
            <th>State</th>
            <th>size</th>
            <th>actions</th>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        {/* <Json>{{ jobs }}</Json>; */}
      </>
    );
  }

  return <Page fluid>{content} </Page>;
};

export default ReportingAdministrationContainer;
