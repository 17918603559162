import { Counting } from '@certhon/domain-models';
import classNames from 'classnames';
import { Parser } from 'expr-eval';
import * as React from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import artikelCode from '../../common/logic/artikelCode';
import { LocationWithDetails, Stock } from '../../modules/locations';
import { InsertableCounting } from '../../stores/countingStore';
import { MakeTree } from '../Tree/common';
import stl from './CountingInputComponent.module.scss';
import loader from '../../assets/loader.gif';

const msg = defineMessages({
  COUNT_PIECES: {
    id: 'inventory.counting.countPieces',
    defaultMessage: 'Count pieces',
  },
  COUNT_PACKAGING: {
    id: 'inventory.counting.countPackages',
    defaultMessage: 'Count packages',
  },
  WEIGH: { id: 'inventory.counting.weigh', defaultMessage: 'Weigh' },
});

/** Sanitize and parse amount input string to number or NaN */
export function parseAmountInput(input: string): number {
  try {
    return Parser.evaluate(
      (input.trim() || '0').replace(/[⋅×xX]/g, '*').replaceAll(',', '.'),
    );
  } catch (error) {
    return NaN;
  }
}

function handleTypeCharInInput(
  event: React.MouseEvent,
  inputRef: React.RefObject<HTMLInputElement>,
  char: string,
  setValue: (v: string) => void,
): any {
  event.preventDefault(); // dont move focus to the button
  event.stopPropagation();
  const input = inputRef.current;
  if (input) {
    let { selectionStart, selectionEnd, value } = input;
    if (selectionStart === null) {
      selectionEnd = selectionStart = value.length + 1;
    }
    const newValue = Array.from(value);
    newValue.splice(
      selectionStart,
      selectionEnd! - selectionStart,
      ` ${char} `,
    );
    setValue(newValue.join('').replace(/ {2,10}/g, ' '));

    input.focus();
    setTimeout(() => {
      input.setSelectionRange(selectionStart! + 3, selectionEnd! + 3);
    });
  }
}

/**
 * Custom numeric input component for inventory counting
 * FIXME: extract logic for programatically positioning component
 */
export interface CountingInputComponentProps {
  location: MakeTree<LocationWithDetails>;
  stock: Stock | null;
  counting: Counting | null;
  onGoNext: () => void;
  saveCounting: (counting: InsertableCounting) => Promise<void>;
  filterFunction: (location_id: string, stock: Stock) => boolean;
}
const CountingInputComponent: React.FC<CountingInputComponentProps> = ({
  location,
  stock,
  counting,
  filterFunction,
  onGoNext,
  saveCounting,
}) => {
  /*
   * References
   */
  const inputRef = React.useRef<HTMLInputElement>(null);
  const nextBtnRef = React.useRef<HTMLButtonElement>(null);
  // const uiRef = React.useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const verifyAndReturnCountingAmount = () => {
    if (counting?.amount_input) {
      if (parseAmountInput(counting.amount_input) === counting.amount) {
        // chech the amount_input, just in case
        return counting.amount_input;
      } else {
        // if amount and amount_input do not match, use amount
        return counting.amount.toString();
      }
    }
    return '';
  };

  const [amountInput, setAmountInput] = React.useState<string>(
    verifyAndReturnCountingAmount,
  );

  const [countAnyway, setcountAnyway] = React.useState<boolean>(false);

  /* Whenever location changes focus and update amount reset count anyway */
  React.useEffect(() => {
    inputRef?.current?.focus();
    nextBtnRef?.current?.focus();
    setError(null);
    setcountAnyway(false);
    setAmountInput(verifyAndReturnCountingAmount());
  }, [location, counting]);

  /** Parsed amound from amountInput (or NaN) */
  const amount = React.useMemo(() => parseAmountInput(amountInput), [
    amountInput,
  ]);

  const handleAmountInputChange = React.useCallback<
    React.FormEventHandler<HTMLInputElement>
  >(event => {
    setAmountInput(event.currentTarget.value);
  }, []);
  const [error, setError] = React.useState<Error | null>(null);

  const handleAdd = React.useCallback<React.MouseEventHandler<any>>(
    event => handleTypeCharInInput(event, inputRef, '+', setAmountInput),
    [],
  );
  const handleSubtract = React.useCallback<React.MouseEventHandler<any>>(
    event => handleTypeCharInInput(event, inputRef, '-', setAmountInput),
    [],
  );
  const handleMultiply = React.useCallback<React.MouseEventHandler<any>>(
    event => handleTypeCharInInput(event, inputRef, '×', setAmountInput),
    [],
  );
  const handleDevide = React.useCallback<React.MouseEventHandler<any>>(
    event => handleTypeCharInInput(event, inputRef, '/', setAmountInput),
    [],
  );

  const [isSubmitActive, setIsSubmitActive] = React.useState<boolean>(false);

  const handleSubmit = React.useCallback<React.FormEventHandler>(
    event => {
      event.preventDefault();
      if (!isNaN(amount)) {
        // note that saveCounting also caused the next counting-item to be selected
        setError(null);
        setIsSubmitActive(true);
        saveCounting({
          amount: amount,
          artikel_id: stock!.recnum,
          location_id: location.id,
          amount_input: amountInput,
          // countAnyway means that that this counting was not required by this counting request
          context: countAnyway ? 'AD_HOC' : 'COUNTING_REQUEST',
        })
          .catch(setError)
          .then(() => setIsSubmitActive(false));
      }
    },
    [amountInput, location.id, amount, saveCounting, stock, countAnyway],
  );

  const needsCounting = stock && filterFunction(location.id, stock);

  return (
    <>
      <div className={stl.root}>
        {/* <h6 style={{ borderBottom: 'solid thin black' }}>Location</h6> */}
        {error && <Alert bsStyle="danger">{error.message}</Alert>}
        {/* <div>
          <div className="col-xs-7">
            <FormGroup>
              <ControlLabel>Path</ControlLabel>
              <br />
              <div className={stl.path}>{formatPath(location).join(' / ')}</div>
            </FormGroup>
          </div>
          <div className="col-xs-5">
            <FormGroup>
              <ControlLabel>Code</ControlLabel>
              <br />
              {formatCode(location).join('')}
            </FormGroup>
          </div>
        </div> */}
        {/* <h6 style={{ borderBottom: 'solid thin black' }}>Product</h6> */}
        <div>
          {!needsCounting && (
            <div className="col-xs-12">
              <Alert bsStyle="warning">
                Hoeft niet geteld te worden.{' '}
                <button
                  className="btn-link btn-sm"
                  onClick={() => setcountAnyway(f => !f)}
                >
                  <strong>Toch {countAnyway && 'niet'} tellen</strong>
                </button>
              </Alert>
            </div>
          )}
          {!stock && location.unsalable && (
            <div className="col-xs-12">
              <Alert bsStyle="warning">
                Locatie
                {location.name_replaces_path && (
                  <>
                    , met omschrijving: <em>{location.description}</em>,
                  </>
                )}{' '}
                is <strong>incourant</strong>; er hoeft niets geteld te worden.
              </Alert>
            </div>
          )}
          <div></div>
          <div className="col-xs-3">
            <FormGroup>
              <ControlLabel>Code</ControlLabel>
              <br />
              {(stock && <>{artikelCode(stock)}</>) || '-'}
            </FormGroup>
          </div>
          <div className="col-xs-9">
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <br />
              {(stock && <>{stock.description}</>) || '-'}
            </FormGroup>
          </div>
          <div>
            <div className="col-xs-3">
              <FormGroup>
                <ControlLabel>Unit:</ControlLabel>{' '}
                <span
                  className={classNames(
                    stock && stock.unit !== 'pc' && stl.danger,
                  )}
                >
                  {(stock && <>{stock.unit}</>) || '-'}
                </span>
              </FormGroup>
            </div>
            <div className="col-xs-9">
              <FormGroup>
                <ControlLabel>Method:</ControlLabel>{' '}
                <FormattedMessage
                  {...msg[
                    location.prescribed_counting_method || 'COUNT_PIECES'
                  ]}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <>
          {needsCounting || countAnyway ? (
            <>
              <div>
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit}>
                    <FormGroup>
                      <InputGroup>
                        <FormControl
                          inputMode="decimal"
                          onFocus={e => e.preventDefault()}
                          value={amountInput}
                          placeholder="15 × 250"
                          onChange={handleAmountInputChange as any}
                          type="text"
                          inputRef={inputRef as any}
                        />
                        <InputGroup.Addon>
                          {isNaN(amount) ? <em>invalid</em> : amount}
                        </InputGroup.Addon>
                        <InputGroup.Button>
                          <Button
                            type="submit"
                            disabled={isNaN(amount) || isSubmitActive}
                          >
                            {isSubmitActive ? (
                              <img alt="progress indicator" src={loader} />
                            ) : (
                              'submit'
                            )}
                          </Button>
                        </InputGroup.Button>
                      </InputGroup>
                    </FormGroup>
                  </form>
                </div>
              </div>
              <div>
                <div className="col-xs-12">
                  <div className={stl.spread_buttons}>
                    <Button onClick={handleAdd}>+</Button>
                    <Button onClick={handleSubtract}>-</Button>
                    <Button onClick={handleMultiply}>&times;</Button>
                    <Button onClick={handleDevide}>&divide;</Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="col-xs-12">
                <div className={stl.spread_buttons}>
                  <button
                    className="btn btn-default"
                    onClick={onGoNext}
                    ref={nextBtnRef}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default CountingInputComponent;
