import { BrowserHistory } from 'history';
import * as React from 'react';
import { Route, Router, Switch } from 'wouter';
import Activate from '../components/Activate';
import BrokenComponent from '../components/BrokenComponent';
import ConfirmEmailPage from '../components/ConfirmEmail';
import DocumentationPage from '../components/DocumentationPage';
import Forgot from '../components/Forgot';
import ListDetails from '../components/ListDetails';
import ListReport from '../components/ListReport';
import Login from '../components/Login';
import NotFound from '../components/NotFound';
import Recover from '../components/Recover';
import TemplateDetails from '../components/TemplateDetails';
import TemplatesOverview from '../components/TemplatesOverview';
import TicketDetails from '../components/TicketDetails/index';
import AccountDetailsContainer from '../containers/AccountDetailsContainer';
import AccountOverviewContainer from '../containers/AccountOverviewContainer';
import CacheAdministrationContainer from '../containers/CacheAdministrationContainer';
import CountingRequestLayout from '../containers/CountingRequest.Layout';
import InventorySearchContainer from '../containers/IndentorySearch.Container';
import InventoryLocationLayout from '../containers/InventoryLocation.Layout';
import LabelsOverviewContainer from '../containers/LabelsOverviewContainer';
import MaterialListLayout from '../containers/MaterialList.layout';
import MeetingsDetailsContainer from '../containers/MeetingsDetailsContainer';
import MultiProjectTicketReportContainer from '../containers/MultiProjectTicketReportContainer';
import MultiTicketReportContainer from '../containers/MultiTicketReportContainer';
import MyActivity from '../containers/MyActivity/index';
import MyDashboard from '../containers/MyDashboard/index';
import MyFeedbackContainer from '../containers/MyFeedbackContainer';
import MyTasks from '../containers/MyTasks/index';
import MyTickets from '../containers/MyTickets/index';
import ProjectCatalogDetails from '../containers/ProjectCatalogDetails';
import ProjectDetails from '../containers/ProjectDetails';
import ProjectsOverview from '../containers/ProjectsOverview';
import SettingsContainer from '../containers/SettingsContainer';
import StatsContainer from '../containers/StatsContainer';
import WorkDetails from '../containers/WorkDetails/index';
import Columnlayout from '../test/ColumnLayout';
import ViewPortTest from '../test/viewport';
import { requireAuthentication as auth } from '../utils/auth';
import { flag } from '../utils/component';
import { createWouterHook } from './createWouterHook';
import matcher from './matcher';
import ReactMdeTestPage from './ReactMdeTestPage';
import { Redirect } from './Redirect';
import { TestError, TestLocationLabels, TestScanner, TestSpinners } from './TestPages';
import ReportingAdministrationContainer from '../containers/ReportingAdministrationContainer';
 

const AppRoutes: React.FC<{ history: BrowserHistory }> = ({ history }) => (
  <Router hook={createWouterHook(history)} matcher={matcher }>
    <Switch>
      {/* redirects */}
      <Route path="/">
        <Redirect to="/projects" />
      </Route>
      <Route path="/qr/location/:id">{params => <Redirect to={`/inventory/${params.id}`} />}</Route>
      
      {/* legacy redirects */}
      <Route path="/project/:recnum/docs/file/:fileId?">{({ recnum, fileId }) => <Redirect to={`/project/${recnum}/docs/${fileId}`} />}</Route>

      {/* public pages */}
      <Route path="/activate"                                                                         component={Activate} />
      <Route path="/confirm-email"                                                                    component={ConfirmEmailPage} />
      <Route path="/docs/:doc?"                                                                       component={DocumentationPage} />
      <Route path="/forgot"                                                                           component={Forgot} />
      <Route path="/login"                                                                            component={Login} />
      <Route path="/recover"                                                                          component={Recover} />

      {/* protected pages */}
      <Route path="/cache-administration"                                                             component={auth(CacheAdministrationContainer)} />
      <Route path="/reporting-administration"                                                         component={auth(ReportingAdministrationContainer)} />
      <Route path="/accounts"                                                                         component={auth(AccountOverviewContainer)} />
      <Route path="/accounts/:id"                                                                     component={auth(AccountDetailsContainer)} />
      <Route path="/dashboard"                                                                        component={auth(MyDashboard)} />
      <Route path="/dashboard/activity"                                                               component={auth(MyActivity)} />
      <Route path="/dashboard/tasks"                                                                  component={auth(MyTasks)} />
      <Route path="/dashboard/tickets"                                                                component={auth(MyTickets)} />
      <Route path="/feedback"                                                                         component={auth(MyFeedbackContainer)} />
      <Route path={["/inventory/lists/:recnumOrNew?"] as any}                                         component={auth(MaterialListLayout)}/>
      <Route path={["/inventory/search"] as any}                                                      component={auth(InventorySearchContainer)}/>
      <Route path={["/inventory"                                                             ,
                    "/inventory/:selectedLocationId",
                    "/inventory/:selectedLocationId/counting-request/:countingRequestRecnum"] as any} component={auth(InventoryLocationLayout)}></Route>
      <Route path="/inventory/:selectedLocationId/print-labels"                                       component={auth(flag('printLabels', InventoryLocationLayout))} />
      <Route path={["/counting-requests/:recnumOrNew/count/:parentLocationId",
                    "/counting-requests/:recnumOrNew?"] as any}                                       component={auth(CountingRequestLayout)}/>
      <Route path="/labels"                                                                           component={auth(LabelsOverviewContainer)} />
      <Route path="/list/:listId/report"                                                              component={auth(ListReport)} />
      <Route path="/list/:listId/:nodeId?"                                                            component={auth(ListDetails)} />
      <Route path="/meeting/:minuteId"                                                                component={auth(MeetingsDetailsContainer)} />
      <Route path="/project/:projectId"                                                               component={auth(ProjectDetails)} />
      <Route path="/project/:projectId/docs/:fileId?"                                                 component={auth(ProjectCatalogDetails)} />
      <Route path="/projects"                                                                         component={auth(ProjectsOverview)} />
      <Route path="/projects/:projectId/ticketsreport"                                                component={auth(MultiProjectTicketReportContainer)} />
      <Route path="/settings"                                                                         component={auth(SettingsContainer as any)} />
      <Route path="/stats"                                                                            component={auth(StatsContainer)} />
      <Route path="/template/:listId/:nodeId?"                                                        component={auth(TemplateDetails)} />
      <Route path="/templates"                                                                        component={auth(TemplatesOverview)} />
      <Route path="/tickets/:ticketId"                                                                component={auth(TicketDetails)} />
      <Route path="/works/:workRecnum"                                                                component={auth(WorkDetails)} />
      <Route path="/works/:workRecnum/ticketsreport"                                                  component={auth(MultiTicketReportContainer)} />

      {/* test pages */}
      <Route path="/scanner"                                                                          component={auth(TestScanner)} />
      <Route path="/err"                                                                              component={auth(TestError)} />
      <Route path="/err2"                                                                             component={auth(BrokenComponent)} />
      <Route path="/spinner"                                                                          component={auth(TestSpinners)} />
      <Route path="/testlabels"                                                                       component={auth(TestLocationLabels)} />
      <Route path="/react-mde"                                                                        component={auth(ReactMdeTestPage)} />
      <Route path='/viewporttest'                                                                     component={auth(ViewPortTest) } />
      <Route path={['/drilldown',
                    '/drilldown/hello',
                    '/drilldown/hello/:name'] as any}                                                 component={Columnlayout as any } />

      {/* 404 */}
      <Route path="/:rest*"                                                                           component={NotFound} />
    </Switch>
  </Router>
);

export default AppRoutes;
